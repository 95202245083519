var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "선임명 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: !_vm.isOld,
                            editable: _vm.editable,
                            disabled: true,
                            afterIcon:
                              _vm.editable && !_vm.isOld
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchSenior",
                                      color: "teal",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeSenior",
                                      color: "red",
                                    },
                                  ]
                                : void 0,
                            label: "선임명",
                            name: "seniorName",
                          },
                          on: {
                            searchSenior: _vm.openSenior,
                            removeSenior: _vm.removeSenior,
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.seniorName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "seniorName",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.seniorName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-multi-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            isArray: false,
                            codeGroupCd: "RELATED_LAWS_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "관련법규",
                            name: "relatedLaws",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.relatedLaws,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "relatedLaws",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.relatedLaws",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            rows: 2,
                            name: "qualifiyRequire",
                            label: "자격요건",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.qualifiyRequire,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "qualifiyRequire",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.qualifiyRequire",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            name: "relatedReason",
                            label: "관련근거",
                            rows: 2,
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.relatedReason,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "relatedReason",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.relatedReason",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            name: "seniorReason",
                            label: "수행역할",
                            rows: 2,
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.seniorReason,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "seniorReason",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.seniorReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "선임 정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && _vm.isOld,
                                expression: "editable && isOld",
                              },
                            ],
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.deleteData },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable,
                                expression: "editable",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.seniorDismissalStatus,
                              mappingType: _vm.mappingType,
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveData,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            disabled: _vm.isOld,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.plantCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "plantCd",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            data: _vm.seniorDismissalStatus,
                            type: "dept_user",
                            deptValue: "seniorDeptCd",
                            label: "부서 & 선임자",
                            name: "seniorUserId",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.seniorUserId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "seniorUserId",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.seniorUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "선임일",
                            name: "seniorDate",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.seniorDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "seniorDate",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.seniorDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-radio", {
                                attrs: {
                                  editable: _vm.editable,
                                  comboItems: _vm.dismissalFlagItems,
                                  label: "해임여부",
                                  name: "dismissalFlag",
                                },
                                on: { datachange: _vm.datachange },
                                model: {
                                  value:
                                    _vm.seniorDismissalStatus.dismissalFlag,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.seniorDismissalStatus,
                                      "dismissalFlag",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "seniorDismissalStatus.dismissalFlag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.editable,
                                  disabled:
                                    _vm.seniorDismissalStatus.dismissalFlag !==
                                    "Y",
                                  label: "해임일",
                                  start: _vm.seniorDismissalStatus.seniorDate,
                                  name: "dismissalDate",
                                },
                                model: {
                                  value:
                                    _vm.seniorDismissalStatus.dismissalDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.seniorDismissalStatus,
                                      "dismissalDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "seniorDismissalStatus.dismissalDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm q-mt-sm",
                  attrs: { title: "자격증 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관련자격증",
                            name: "relatedCertificate",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.relatedCertificate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "relatedCertificate",
                                $$v
                              )
                            },
                            expression:
                              "seniorDismissalStatus.relatedCertificate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "자격증번호",
                            name: "certificateNo",
                          },
                          model: {
                            value: _vm.seniorDismissalStatus.certificateNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "certificateNo",
                                $$v
                              )
                            },
                            expression: "seniorDismissalStatus.certificateNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            label: "자격취득일",
                            name: "certificateAcquiDate",
                          },
                          model: {
                            value:
                              _vm.seniorDismissalStatus.certificateAcquiDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.seniorDismissalStatus,
                                "certificateAcquiDate",
                                $$v
                              )
                            },
                            expression:
                              "seniorDismissalStatus.certificateAcquiDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: _vm.editable,
                            label: "관련 선해임자료 및 자격증",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c("c-table", {
                ref: "table",
                attrs: {
                  title: "관련 교육과정 및 이수일",
                  columns: _vm.grid.columns,
                  data: _vm.seniorDismissalStatus.eduStatuses,
                  gridHeight: _vm.grid.height,
                  filtering: false,
                  isExcelDown: false,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }